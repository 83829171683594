body {
    margin: 0;
    font-family: 'Muli', 'Open Sans', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #555;
    font-size: 16px;
}

h1 {
    font-weight: normal;
}


.section-header {
    text-align: center;
    font-size: 18px;
    text-transform: UPPERCASE;
    color: #253D60;
    margin: 15px;
}

a {
    color: #253D60;
    text-decoration: none;

}

a:active, a:hover {
    color: #2AABE3;
}

section {
    width: 100%;
}

button, .button {
    display: flex;
    position: relative;
    width: 205px;
    height: 50px;
    background-color: #2AABE3;
    color: #fff;
    border-radius: 8px;
    text-transform: UPPERCASE;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    cursor: pointer;
    border: none;
    align-items: center;
    justify-content: center;

}

button:hover, .button:hover {
    background-color: #253D60;
    transition: 0.2s;
    box-shadow: 0 5px 25px -5px rgba(0, 0, 0, 1);
    transform: scale(1.05);
}

button:active, .button:active {
    box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.75);
    transform: scale(.98)
}


.service-intro {
    opacity: 0.8;
    font-size: 18px;
}

.side-padded {
    padding: 20px;
}

.service-section-heading {
    color: #2AABE3;
    font-size: 24px;
    text-transform: UPPERCASE;
    margin: 40px 0;
}

.section-header h1 {
    font-size: 1.8rem;
}

.service-points {
    list-style: none;
}

.service-points li {
    flex-direction: row;
    font-style: italic;
    padding-bottom: 10px;
    font-size: 18px;
    align-items: center;
    position: relative;
    padding-left: 40px;
}

.service-points li::before {
    content: "+";
    color: #2AABE3;
    position: absolute;
    left: 0;
}


.sub-service {
    max-width: 280px;
    text-align: center;
    padding: 50px;
}

.sub-service h1 {
    font-size: 20px
}

.sub-service .service-header {
    color: #2AABE3;
    text-transform: UPPERCASE;

}

.sub-services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.enquire {
    padding: 30px 0;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1000px) {
    button, .button {
        width: 160px;
        height: 40px;
        font-size: 0.8rem;

    }
}


.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

ul {
    list-style: none;
}
