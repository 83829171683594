.erm-services {
    display: flex;
    flex-wrap: wrap;
}

.erm-service {
    display: flex;
    text-transform: UPPERCASE;
    text-align: center;
    flex-direction: column;
    padding: 20px;
    min-width: 300px;
    flex: 1;
}

.erm-service-top {
    background-color: #2AABE3;
    padding: 20px;
    color: #ffffff;
}

.erm-service-bottom {
    background-color: #e5e5e5;
    padding: 20px;
    color: #253D60;
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
}
