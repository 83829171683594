.footer {
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: 40px 0;
}

.footer-logo {
    margin-right: 25px;
}

.footer-logos {
    display: flex;
}

.footer-content {
    display: flex;
    align-items: center;
    font-size: 12px;
}


@media only screen and (max-width: 800px) {
    .footer {
        padding: 40px 20px;
        height: 40px;
        justify-content: center;
    }


    .footer-logo img {
        display: flex;
        width: 40px;

    }
}


@media only screen and (max-width: 1220px) {
    .footer {
        padding-left: 20px;
        padding-right: 20px;
    }
}
