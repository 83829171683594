.services {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.service-tiles {
    display: flex;
    align-self: center;
    margin: 0;
}

.tile {
    flex: 1;
    display: flex;
    position: relative;
    margin: 0;
    height: 400px;
    transition: 0.3s ease-in-out;
}

.tile.reverse {
    flex-direction: row-reverse;
}


.tile:hover {
    /*transform: scale(1.01);*/
}

.tile-image-wrapper {
    flex: 1;
    position: relative;
    display: flex;
}

.tile-text-content {
    display: flex;
    flex: 1;
    background: #F4F4F4;
    text-align: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
}

.tile-text-content p {
    padding: 20px;
}

.tile-text-content h1, .contact-us-tile-content h1 {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: bold;
    text-transform: UPPERCASE;
}


.tile img {

    object-fit: cover;
    width: 100%;
    height: 100%;
}

.contact-us-tile-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #F4F4F4;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    padding: 30px 0;
}

@media only screen and (max-width: 1000px) {
    .tile {
        height: 300px;
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 800px) {
    .tile, .tile.reverse {
        flex-direction: column;
        height: auto;
    }

    .service-tiles {
        flex-direction: column;
    }
}

@media only screen and (max-width: 400px) {
    .tile {
        height: auto;
        font-size: 0.9rem;
    }
}
