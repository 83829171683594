.event-image {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 60px;
    background-blend-mode: multiply;
    background: rgba(42, 171, 227, 0.5) no-repeat center;
    background-size: cover;
    color: #fff;
    font-weight: bold;
    padding-left: 10px;
}

.event-month {
    font-size: 54px;
}

.event-day {
    font-size: 144px;
}

.event-content {
    display: flex;
    flex: 2;
    flex-direction: column;
    padding: 0 50px 50px;
    justify-content: space-between;
    background-color: #F4F4F4;
}

.event-content ul {
    padding: initial;
}

.event {
    display: flex;
    align-self: center;
    margin-bottom: 10px;
}

.event p {
    font-size: 1.2rem;
}

.event h1 {
    font-size: 36px;
    text-transform: UPPERCASE;
}

@media only screen and (max-width: 800px) {
    .event {
        flex-direction: column;
    }

    .event-content {
        align-items: flex-start;
    }

    .event h1 {
        font-size: 24px;
    }

}

@media only screen and (max-width: 400px) {
    .event h1 {
        font-size: 20px;
    }

    .event p {
        font-size: 1rem;
    }
}



