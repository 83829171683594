.tm-service {
    display: flex;
    padding: 40px;
    align-items: center;
}

.tm-service-image-wrapper {
    max-width: 350px;
    flex: 1
}


.tm-service-commentary {
    flex: 1;
}

.tm-service-commentary h1 {
    text-transform: UPPERCASE;
    color: #2196F3;

}

@media only screen and (max-width: 800px) {
    .tm-service {
        flex-direction: column;
        text-align: center;
    }
}
