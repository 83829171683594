.people-analytics-pointers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.people-analytics-pointer {
    flex: 1;
    text-align: center;
    transition: 0.45s ease-in-out;
}

.people-analytics-pointer-header-desktop {
    color: #2AABE3;
    text-transform: UPPERCASE;
    margin-bottom: 40px;
    display: block;
    font-size: 20px;
}

.people-analytics-pointer-header-mobile {
    display: none;
    color: #2AABE3;
    text-transform: UPPERCASE;
    font-size: 20px;
}

.people-analytics-sustainability {
    background-color: #253D60;
    color: #2AABE3;
    border-radius: 200px;
    padding: 0 10%;
    font-size: 20px;
    display: flex;
    height: 130px;
}

.people-analytics-sustainability-text {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.people-analytics-sustainability-image-wrapper, .pa-pointer-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.people-analytics-sustainability-image-wrapper img {
    max-width: 60px;
}


.people-analytics-sustainability-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pa-bar {
    height: 100px;
    background-color: #253D60;
    width: 5px;
    margin: 0 auto auto;
    padding-top: 0;;
    display: flex;
    flex-direction: column;
}

.pa-service {
    display: flex;
    justify-content: space-around;
    padding: 40px 0;
    font-size: 1.1rem;
    flex-wrap: wrap;
    align-items: center;

}

.pa-service h1 {
    color: #2196F3;
    text-transform: UPPERCASE;
}

.pa-service.reverse {
    flex-direction: row-reverse;

}

.pa-service-image-wrapper {
    max-width: 250px;
    position: relative;
    box-shadow: 0 1px 55px -1px transparent;
}

.pa-service-image-wrapper img {
    width: 100%;
    flex: 1;

}


@media only screen and (max-width: 900px) {
    .people-analytics-pointers {
        display: block;
    }

    .people-analytics-pointer-header-desktop {
        display: none;
    }

    .people-analytics-pointer-header-mobile {
        display: block;

    }

    .people-analytics-sustainability-image-wrapper {
        display: none;
    }

    .people-analytics-sustainability {
        background-color: #253D60;
        height: 100px;
    }

    .pa-bar {
        height: 70px;
    }
}

@media only screen and (max-width: 800px) {
    .pa-service, .pa-service.reverse {
        flex-direction: column;
        padding: 40px 0;
        font-size: 1rem;
    }


    .pa-service-image-wrapper {
        position: relative;
        box-shadow: 0 1px 55px -1px transparent;
    }

    .pa-service-commentary {
        padding-top: 20px;
    }

    .pa-service-commentary h1 {
        text-align: center;
    }
}

@media only screen and (max-width: 750px) {
    .people-analytics-sustainability-text {
        font-size: 0.84rem;
    }
}
