.navbar {
    display: flex;
    height: 50px;
    align-items: center;
    padding: 25px 0;
    width: 100%;
    justify-content: space-between;
    max-width: 1200px;
}

.logo-wrapper {
    position: relative;
    cursor: pointer;
    flex: 1;
    max-width: 200px;
    max-height: 100%;
    display: flex;
    align-items: center;
}

.logo-wrapper img {
    width: 100%;

}

.menu-links {
    display: flex;
    list-style: none;
    text-transform: UPPERCASE;
    align-items: center;
}

.menu-links > li {
    padding: 20px;
    position: relative;
}

.dropdown {
    opacity: 1;
    position: absolute;
    transition: all 0.5s ease;
    left: 0;
    display: block;
    z-index: 99;
    list-style: none;
    min-width: 300px;
    padding: 30px 5px 5px;
    border-radius: 8px;
    -webkit-box-shadow: -21px 20px 23px -25px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -21px 20px 23px -25px rgba(0, 0, 0, 0.75);
    box-shadow: -21px 20px 23px -25px rgba(0, 0, 0, 0.75);
    background-color: #ffffff;
}

.dropdown.hidden {
    display: none;
}

.dropdown > li {
    padding: 15px;
}

.hamburger {
    position: absolute;
    cursor: pointer;
    right: 30px;
    top: 30px;
    display: none;
}

.service-link {
    display: block;
}

.menu {

}

@media only screen and (max-width: 800px) {
    .dropdown {
        min-width: auto;
    }

    .logo-wrapper {
        padding-left: 20px;
    }

    .navbar {
        padding: 20px 0;
    }

    .menu-links {
        flex-direction: column;
        position: absolute;
        background: white;
        z-index: 99;
        text-align: left;
        top: 70px;
        width: 100%;
        padding: 0 0 0 20px;
        box-sizing: border-box;
    }

    .menu-links.hidden, .menu-links.hidden * {
        display: none;
        height: 0;
    }

    .menu-links, .menu-links li .dropdown {
        display: block;
    }


    .menu-links > li {
        border-bottom: 1px solid #e5e5e5;
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
    }

    .menu-links > li.has-sub-menu {
        padding-bottom: 0;
        box-sizing: border-box;

    }

    .menu-links li .dropdown {
        position: relative;
        border-radius: 0;
        box-shadow: none;
        -moz-box-shadow: none;
        opacity: 1;
        display: block;;
        padding: 10px 0 0;

    }

    .menu-links li .dropdown li {
        border-top: 1px solid #e5e5e5;
        padding: 10px;
        text-transform: none;
    }

    .hamburger {
        display: block;
        color: #2AABE3;
    }

    .hamburger .bar1, .hamburger .bar2, .hamburger .bar3 {
        width: 30px;
        height: 3px;
        background-color: #2AABE3;;
        margin: 6px;
        transition: 0.25s ease-in-out;
    }

    .hamburger .bar1.open {
        -webkit-transform: rotate(-45deg) translate(-9px, 6px);
        transform: rotate(-45deg) translate(-6px, 6px);
    }

    .hamburger .bar2.open {
        opacity: 0;
    }

    .hamburger .bar3.open {
        -webkit-transform: rotate(45deg) translate(-8px, -8px);
        transform: rotate(45deg) translate(-6px, -8px);
    }
}

.services-link {
    cursor: pointer;
}

@media only screen and (max-width: 1220px) {
    .logo-wrapper {
        padding-left: 20px;
    }
}

@media only screen and (max-width: 400px) {
    .logo-wrapper {
        max-width: 150px;
    }

}

