.mc-service {
    width: 280px;
    text-align: center;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content:  space-evenly;;

}

.mc-service h1 {
    font-size: 20px
}

.mc-service .service-header {
    color: #2AABE3;
    text-transform: UPPERCASE;

}

.mc-services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
