.App {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-content {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
}

.hero {
    height: 400px;
    width: 100%;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;

}

.hero-contents {
    position: relative;
    color: #dfdfdf;
    font-size: 1.8rem;
    padding: 30px;
}

.hero-contents h1 {
    font-weight: bold;
}

.hero img {
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .App {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        font-size: 1.3rem;
    }
}

@media only screen and (max-width: 750px) {
    .hero-contents {
        font-size: 1.3rem;
    }
}

@media only screen and (max-width: 400px) {
    .hero-contents {
        font-size: 1rem;
    }
}

