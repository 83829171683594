/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1rem;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.service-option {
    padding-left: 30px;
}

.service-category-option {
    padding-top: 20px;
}

.form-input-group {
    display: flex;
    flex-wrap: wrap;
}

.form-input-group input, textarea {
    min-width: 300px;
    border: none;
    color: inherit;
    font-size: 1rem;
    margin: 10px 0;
}

.form-input-group input {
    height: 35px;
}

.form-input-group textarea {
    height: 250px;
}

.form {
    background-color: #F4F4F4;
    padding: 50px;
    margin-top: 50px;
    color: #253D60;;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
}

.field-group {
    display: flex;
    flex-direction: column;
    padding: 0 35px;
    flex: 1;
}

.field-group label {
    color: #253D60;
    font-size: 0.9rem;
}

.field-group-row {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;

}

.form h1 {
    font-weight: bold;
    font-size: 2rem;
}

.form h2 {
    font-weight: normal;
}

.form .submit {
    align-self: center;
}

.service-selection {
    padding: 0 35px 35px;
}

.submit-success {
    background-color: #F4F4F4;
    padding: 50px;
    margin-top: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    text-align: center;
}

.submit-success h1 {
    color: #2AABE3;
    font-weight: bold;
    font-size: 2rem;
    display: flex;
}

.contact-info {
    color: #ffffff;
    text-align: left;
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    padding: 30px;
    justify-content: center;
}

.contact-info-row {
    position: relative;
    padding: 5px 0;
    display: flex;
    font-size: 1.3rem;
    align-items: center;
}

.contact-info-row img {
    width: 22px;
}

.contact-info-row .img-wrapper {
    display: flex;
    position: relative;
    width: 40px;
}

.contact-hero {
    display: flex;
    height: 400px;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 750px) {
    .contact-info {
        font-size: 1rem;
    }

    .form-input-group input, textarea {
        min-width: initial;
    }

    .form {
        padding: 50px 0;
    }

}

@media only screen and (max-width: 450px) {
    .form h1{
        font-size: 1.5rem;
    }

    .form h2{
        font-size: 1.5rem;
    }

    .contact-info-row {
        font-size: 1rem;
    }
}

